<template>
	<div class="header-container">
		<div class="header-left">
			<div class="username-box">
				<div>用户名 : {{userName}}</div>
			</div>
			<div>{{currentTime}}</div>
		</div>
		<div class="header-right">
			<div>
				<!-- <el-button style="margin: 0 0.625rem;" size="medium" type="primary" icon="el-icon-message" circle @click="showMsgDialog"></el-button> -->
				<el-button type="danger" size="medium" icon="el-icon-switch-button" circle @click="loginOut"></el-button>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'myheader',
		data() {
			return {
				avatarUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				timer: "",//定义一个定时器的变量
				currentTime: '0-0-0-0', // 获取当前时间
				equipmentNo: sessionStorage.getItem("equipmentNo"),
				isNotTouch: sessionStorage.getItem("isNotTouch")
			}
		},
		
		created() {
			var that = this;
			
			that.timer = setInterval(function() {
				that.currentTime = that.getCurrentTime()
			},1000)
		},
		beforeDestroy() {
		    if (this.timer) {
		      clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
		    }
		},
		props: {
			userName: {
				type: String,
				default: ""
			}
		},
		methods: {
			getCurrentTime() {
				let aData = new Date();
				let year = aData.getFullYear();
				let month = aData.getMonth() < 9 ? "0" + (aData.getMonth() + 1) : aData.getMonth() + 1;
				let date = aData.getDate() <= 9 ? "0" + aData.getDate() : aData.getDate();
				let hour = aData.getHours() <= 9 ? "0" + aData.getHours() : aData.getHours();
				let minute = aData.getMinutes() <= 9 ? "0" + aData.getMinutes() : aData.getMinutes();
				let second = aData.getSeconds() <= 9 ? "0" + aData.getSeconds() : aData.getSeconds();
				return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":"+ second;
			},
			// 弹出消息提示框
			showMsgDialog() {
				this.$bus.$emit("showMsgDialog")
			},
			//退出系统
			loginOut() {
				this.$confirm('此操作将会退出系统, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error'
				}).then(() => {
					this.$router.push({path: '/login', query: {SN: sessionStorage.getItem("equipmentNo"), cardNo: sessionStorage.getItem("cardNo"), NotTouch: sessionStorage.getItem("isNotTouch")}})
					this.$message.success("退出成功")
					sessionStorage.clear()
				}).catch(() => {})
			}
		}
	}
</script>
<style>
</style>
<style lang="scss" scope>
$textc: #606266;
.header-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.header-left{
		width: 78%;
		margin-right: 1.375rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: $textc;
		letter-spacing: 2px;
		.username-box {
			display: flex;
			align-items: center;
			justify-content: center;
			div {
				margin: 0 0.625rem;
			}
		}
	}
	.header-right{
		width: 25.63%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 1.5rem;
		font-family:PingFang SC;
		font-weight:400;
		color: $textc;
	}
}
</style>
