<template>
	<div class="footer-container">
		<div class="img"><img src="~assets/img/tz.png" alt=""></div>
		<div class="txt"> {{footerContent}} </div>
	</div>
</template>

<script>
	export default {
		name: 'myfooter',
		data() {
			return {
				currentTime: ""
			}
		},
		props: {
			footerContent: {
				type: String,
				default: "欢迎光临"
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scope>
$txtC: #3A84AB;
.footer-container {
	width: 100%;
	margin-top: 1.25rem;
	height: 3.125rem;
	background: white;
	white-space: nowrap;
	overflow: hidden;
	box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
	font-size: 1.625rem;
	font-family: PingFang SC;
	font-weight: 500;
	border-radius: 0.625rem;
	color: rgba(58,132,171,1);
	letter-spacing: 2px;
	line-height: 3.125rem;
	position: relative;
	.img {
		width: 1.75rem;
		height: 1.75rem;
		position: absolute;
		top: 0.625rem;
		left: 1.25rem;
		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	.txt {
		animation: donghua 15s linear infinite;
		-ms-animation: donghua 15s linear infinite;
		-moz-animation: donghua 15s linear infinite;
		-webkit-animation: donghua 15s linear infinite;
		-o-animation: donghua 15s linear infinite;
	}
	@keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
	
	/* 兼容火狐 */
	@-moz-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
	
	/* Safari 和 Chrome */
	@-webkit-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
	
	@-o-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
	
	@-ms-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
}
</style>
