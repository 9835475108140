<template>
	<div class="menu-list">
		<div class="menu-list-item"
			v-for="(item,index) in menuList"
			:key="item.menuId"
			@click="to(index, item.menuId, item.menuName, item.url, item.icon)"
			:class="index == currentIndex ? 'active' : ''">
			<span><i :class="item.icon"></i></span>
			<span>{{item.menuName}}</span>
			<div class="line"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "mymenu",
	data() {
		return {
			currentIndex: window.sessionStorage.getItem("currentIndex") || 0,
		}
	},
	created() {
	},
	props: {
		menuList: {
			type: Array,
			default: []
		},
	},
	methods: {
		to(index, id, name, path, icon) {
			this.currentIndex = index
			window.sessionStorage.setItem("currentIndex", index)
			this.$emit('to', id, name, path,icon)
		}
	}
}
</script>

<style lang="scss" scoped>
$bgc: #3A88AC;
$textc: #FFFFFD;
.menu-list {
	width: 100%;
	padding-left: 1.25rem;
	.menu-list-item {
		width: 100%;
		height: 4.1rem;
		line-height: 4.1rem;
		color: rgba(244,245,250,1);
		font-size: 1.625rem;
		font-weight: 500;
		font-family: PingFang SC;
		letter-spacing: 2px;
		margin: 1.5625rem 0;
		padding-left: 3.125rem;
		z-index: 99;
		position: relative;
		cursor: pointer;
		&:first-child {
			margin-top: 3.125rem;
		}
		&:last-child {
			margin-bottom: 6.25rem;
		}
		span {
			z-index: 900 !important;
		}
		span:nth-of-type(1) {
			z-index: 900 !important;
			display: inherit-block;
			margin-right: 1.875rem;
			i {
				font-size: 1.75rem;
			}
		}
		
	}
	 .active {
		color: rgba(58,132,171,1);
		.line {
			width: 100%;
			height: 0.625rem;
			background-color: #F4F5FA;
			position: absolute;
			top: 46%;
			left: 3px;
			z-index: -1;
		}
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background: url(~assets/img/menu-active.png) no-repeat;
			background-size: cover;
			top: -50%;
			left: 1px;
			z-index: -1;
		}
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background: url(~assets/img/menu-active.png) no-repeat;
			background-size: cover;
			top: 50%;
			left: 1px;
			transform: scale(1, -1);
			-ms-transform: scale(1, -1); 
			-moz-transform: scale(1, -1); 
			-webkit-transform:scale(1, -1); 
			-o-transform: scale(1, -1); 
			z-index: -1;
		}
	 }
}
</style>
