<template>
	<div class="home">
		<!-- 轮播图 -->
			<!-- <MySlide :pics="pics" :footerContent="footerContent"></MySlide> -->
			<!-- 轮播图 -->
			<div class="aside-container" v-if="isMenuShow">
				<div class="logo-container">
					<div class="logo-box">
						<!-- <div class="logo">
							<img src="~assets/img/company.png" alt="">
						</div> -->
						<div class="txt">VisualSystem</div>
					</div>
					<div class="company">
						{{companyName}}
					</div>
					<div class="menu-container">
						<MyMenu :menuList="menuList" @to="to"></MyMenu>
					</div>
				</div>
			</div>
			<div class="main-container" :class="isFullScreen ? 'fullScreen' : ''">
				<!--头部-->
				<div class="header">
					<MyHeader :userName="userName"></MyHeader>
				</div>
				<!--头部-->
				<div class="content">
					<router-view :pics="pics" :menuIcon="menuIcon" :menuName="menuName" :menuId="menuId"></router-view>
				</div>
				<div class="footer">
					<MyFooter :footerContent="footerContent"></MyFooter>
				</div>
			</div>
	
			<el-dialog title="新的消息" :visible.sync="dialogVisible" width="50%" center>
				<div style="font-size: 1.25rem; display: flex; align-items: center; justify-content: center; color: #ggg;">
					{{msg ? msg : "暂无消息"}}
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="confirmMsg" size="mini" :disabled="msg == ''">收 到</el-button>
					<el-button @click="dialogVisible = false" size="mini">取 消</el-button>
				</span>
			</el-dialog>
	
			<el-dialog title="发消息" :visible.sync="dialogVisible2" width="50%" center>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-card style="height: 31.25rem;">
							<div style="height: 100%;">
								<div class="title">请选择推送的工位</div>
								<el-tree :props="defaultParams" ref="tree" :data="options" show-checkbox node-key="id" @check-change="handleCheckChange">
								</el-tree>
							</div>
						</el-card>
					</el-col>
					<el-col :span="12">
						<el-card style="height: 31.25rem;">
							<div style="height: 100%;">
								<div class="title">请输入发送的内容</div>
								<el-form>
									<el-form-item>
										<el-input type="textarea" v-model="msgContent" :rows="10" placeholder="请输入信息"></el-input>
									</el-form-item>
								</el-form>
							</div>
						</el-card>
					</el-col>
				</el-row>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="sendMsgToDept">发 送</el-button>
					<el-button @click="handleCancelSelect">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import MyHeader from 'components/common/MyHeader'
	import MyFooter from 'components/common/MyFooter'
	import MyMenu from './menu/Menu'
	import MySlide from './Slide.vue'
	import {
		getHomeMenuList,
		getMessage,
		getSopFile,
		getDeptList,
		readMessage,
		sendMsg,
		dataCollection,
		getLightColor
	} from 'network/home'
	import {
		getExceptionListByCardNo
	} from 'network/andonCall'
	import storage from 'utils/storage.js';
	import {
		sendWebsocket,
		closeWebsocket
	} from 'utils/initWebSocket.js'
	// 获取配置文件
	import config from '../static/ipconfig.json'
	export default {
		name: "home",
		data() {
			return {
				currentIndex: 0,
				isPublicityShow: true,
				isUpdate: false,
				isContentShow: true,
				isMenuShow: true,
				isShow: true,
				timer: null,
				companyName: "目视化管理系统",
				// baseUrl: "http://192.168.20.250:88/visual",
				baseUrl: "",
				deptId: sessionStorage.getItem("stationId"),
				pics: [],
				slideFiles: [],
				videos: [],
				fileType: "",
				// 底部公告
				footerContent: "",
				websock: null,
				wsuri: "",
				//消息ID
				msgId: "",
				// 消息
				msg: "",
				dialogVisible: false,
				userName: sessionStorage.getItem("userName"),
				userId: sessionStorage.getItem("userId"),
				menuId: sessionStorage.getItem("menuId"),
				menuName: sessionStorage.getItem("menuName"),
				menuPath: sessionStorage.getItem("menuPath"),
				menuIcon: sessionStorage.getItem("menuIcon"),
				// 菜单列表
				menuList: [],
				//后台返回的工位ID
				deptId: sessionStorage.getItem("stationId"),
				eqId: sessionStorage.getItem("eqId"),
				children: [],
				cardExceptionList: [],
				//工位与工位之前推送消息相关
				dialogVisible2: false,
				// 选择的工位
				options: [],
				// 工位列表树状结构
				defaultParams: {
					label: 'name',
					children: 'children',
				},
				// 滚动字幕
				msgContent: "",
				// 工位列表
				deptsIdList: [],

				/**
				 * 数据采集相关
				 */
				// 设备状态
				deviceStatus: "",
				// 产量
				productCount: 0,
				// 初始产量值
				initProductCount: 0,
				// 产量是否发生变化
				countChange: 0,
				
			}
		},
		beforeMount() {
			console.log("initPage");
		},
		mounted() {
			console.log("initVideo(1)");
			this.getBaseUrl();
			this.initWebSocket();
			this.getSopFile1();
			this.getHomeMenuList1();
			this.getDeptList1();
			this.getMessage1();
			if (this.$route.path == "/home") {
				this.to(2599, "推送文档", "animationVideo", "iconfont icon--s-bofang");
			}
			// 播放视频完成的回调
			window["MediaEnd"] = () => {
				alert(123);
				this.mediaEnd();
			}
			// 初始化三色灯
			window["InitPort"] = () => {
				this.initPort();
			}
		},
		created() {
			console.log("initVideo(1)");
			this.$bus.$on("updateSlide", () => {
				this.getSopFile1();
			})
			this.$bus.$on("showMsgDialog", () => {
				this.dialogVisible2 = !this.dialogVisible2;
			});
			this.$bus.$on("hiddenMenu", () => {
				this.isMenuShow = false;
				this.isFullScreen = true;
			});
			this.$bus.$on("showMenu", () => {
				this.isMenuShow = true;
				this.isFullScreen = false;
			});
			
			this.$bus.$on("changeSlideFile", (data) => {
				// console.log(data);
				this.changeSlideFile(data);
			});
			
			this.$bus.$on("getSlideFile",() => {
				this.getSopFile1();
			})
			
			this.$bus.$on("isPublicityShow", () => {
				this.isPublicityShow = false;
			})
			this.$bus.$on("playVideo", () => {
				
				if(this.videos && this.videos.length > 0) {
					console.log('PlayVideo:' + this.videos[this.currentIndex].fileUrl);
				}else {
					this.slideUpdate();
				}
			})
		},
		methods: {
			// 读取本地的配置文件
			getBaseUrl() {
				this.baseUrl = config.baseUrl;
				sessionStorage.setItem("baseUrl", this.baseUrl);
				this.wsuri = config.wsUri;
				sessionStorage.setItem("wsuri", this.wsuri);
			},
			//轮播图组件刷新
			slideUpdate() {
				this.isUpdate = false;
				this.$nextTick(() => {
					this.isUpdate = true;
				})
			},
			//获取菜单项
			getHomeMenuList1() {
				let dataobj = {
					eqId: this.eqId,
					userId: this.userId
				}
				getHomeMenuList(dataobj).then(res => {
					if (res.data.code !== 0)
						return this.$message.error(res.data.msg);
					this.menuList = res.data.data;
					//this.menuList.unshift(obj);
				})
			},
			to(id, name, path, icon) {
				this.menuId = id;
				this.menuName = name;
				this.menuIcon = icon;
				sessionStorage.setItem("menuId", id);
				sessionStorage.setItem("menuName", name);
				sessionStorage.setItem("menuPath", path);
				sessionStorage.setItem("menuIcon", icon);
				this.$router.push({
					path: "/home/" + path,
					query: {
						id: id,
						name: name
					}
				})
				//文件管理类的菜单项去获取其中的文件资源
				if (path == "processFile") {
					this.$bus.$emit("getFileData", id, name)
				}
			},
			//获取工位列表
			getDeptList1() {
				let obj = {
					eqId: this.eqId
				}
				getDeptList(obj).then(res => {
					// console.log("------------");
					// console.log(res.data.data);
					this.options = this.utils.listToTree(res.data.data, res.data.data[0].pId);
				})
			},

			// 树形结构change事件
			handleCheckChange() {
				let res = this.$refs.tree.getCheckedNodes();
				let arr = [];
				res.forEach((item) => {
					arr.push(item.id);
				})
				let menuArr = this.utils.unique(arr); // 去除重复的节点
				this.deptsIdList = menuArr.join(',');
			},

			//树形结构清空选中事件
			handleCancelSelect() {
				this.dialogVisible2 = false;
				this.$refs.tree.setCheckedKeys([]);
			},
			sendMsgToDept() {
				let obj = {
					deptIds: this.deptId,
					content: this.msgContent,
					deptId: sessionStorage.getItem("deptId")
				}
				sendMsg(obj).then(res => {
					// console.log(res)
					if (res.data.code == 0) {
						this.$message.success(res.data.msg);
						this.dialogVisible2 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				})

			},
			getMessage1() {
				let obj = {
					eqId: this.eqId,
					deptId: this.deptId
				}
				getMessage(obj).then(res => {
					if (res.data.code == 0) {
						this.footerContent = res.data.data.content;
					}
				})
			},
			//确认收到消息
			confirmMsg() {
				this.dialogVisible = false;
				let obj = {
					mid: this.msgId
				}
				readMessage(obj).then(res => {
					if (res.data.code == 0) {
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			initWebSocket() { //初始化weosocket
				const wsuri = this.wsuri + this.eqId + "_" + this.deptId;
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onopen = this.websocketonopen;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
			},
			websocketonopen() { //连接建立之后执行send方法发送数据
				let actions = {
					"test": "12345"
				};
				this.websocketsend(JSON.stringify(actions));
			},
			websocketonerror() { //连接建立失败重连
				this.initWebSocket();
			},
			websocketonmessage(e) { //数据接收
				const resdata = JSON.parse(e.data);
				// console.log(resdata);
				if (resdata.type == 1) {
					this.footerContent = resdata.content;
				} else if (resdata.type == 4) {
					//alert("切换sop资源");
					console.log(resdata);
					var result = "";
					this.pics = [];
					this.videos = [];
					resdata.fileList.forEach(item => {
						item.fileUrl = this.baseUrl + item.fileUrl;
						result = this.utils.matchType(item.fileUrl);
						console.log(item);
						if(result == "image") {
							this.pics.push(item);
						}else {
							this.videos.push(item);
						}
					})
					this.slideUpdate();
				} else {
					this.msgId = resdata.mid;
					this.msg = resdata.content;
					this.dialogVisible = true
				}
			},
			websocketsend(Data) { //数据发送
				this.websock.send(Data);
			},
			websocketclose(e) { //关闭
				console.log('断开连接', e);
			},
			
			//获取作业视频
			getSopFile1() {
				let obj = {
					deptId: this.deptId,
				}
				getSopFile(obj).then(res => {
					var arr = res.data.data;
					// console.log(arr);
					this.pics = [];
					// 文件类型
					var result = "";
					if(!arr) return
					arr.forEach(item => {
						item.fileUrl = this.baseUrl + item.fileUrl;
						result = this.utils.matchType(item.fileUrl);
						if(result == "image") {
							this.pics.push(item);
						}else {
							this.videos.push(item)
						}
					})
				})
			},
			
			changeSlideFile(data) {
				this.pics = [];
				this.pics = data;
				this.slideUpdate();
			},
			/**
			 * 调用app的相关方法
			 * window
			 * */
			 mediaEnd() {
				 // alert(123);
				if(this.videos && this.videos.length == 1) {
					alert(123);
					console.log('PlayVideo:' + '');
					this.slideUpdate();
				}else if(this.videos && this.videos.length > 1) {
					alert(456);
					this.currentIndex += 1;
					console.log('PlayVideo:' + this.videos[this.currentIndex].fileUrl);
					if(this.currentIndex >= this.videos.length - 1) {
						this.currentIndex = 0;
						console.log('PlayVideo:' + '');
						this.slideUpdate();
					}
				}
			 },
			// MediaEnd() {
			// 	alert(123);
			// },
			
			// 初始化三色灯 
			initPort() {
				console.log("Light:INITPORT");
			}
			
		},
		components: {
			MyHeader,
			MyFooter,
			MyMenu,
			MySlide,
		}
	}
</script>
<style>
	.el-button {
		font-size: 1.125rem !important;
		width: 7.5rem;
	}

	.el-tree-node__label {
		padding: 0.625rem 0 !important;
	}

	.el-checkbox__label {
		font-size: 1.25rem !important;

	}
	.el-tree-node__label {
		font-size: 1.125rem !important;
	}

	.el-checkbox__inner {
		width: 1rem;
		height: 1rem;
	}

	.el-dialog__title {
		font-size: 1.25rem !important;
		margin: 1.25rem 0;
	}

	.el-checkbox__inner::after {
		height: 0.5rem !important;
		left: 0.3rem !important;
	}

	.el-textarea__inner {
		font-size: 1.125rem !important;
	}

	.el-message-box__content {
		font-size: 1.125rem !important;
	}
</style>
<style lang="scss" scoped>
	$mainBgc: #F4F5FA;
	$txtC: #3A84AB;
	.home {
		width: 100%;
		height: 100%;
		display: flex;
		// height: 500px;
		overflow: hidden;
		.aside-container {
			width: 20%;
			height: 100%;
			background-image: linear-gradient(180deg, #3a7aa7, #39bec0);
			overflow: hidden;
			overflow-y: auto;

			.logo-container {
				display: flex;
				flex-direction: column;

				.logo-box {
					display: flex;
					align-items: center;
					margin: 2rem 0 0 1rem;

					.logo {
						width: 5rem;
						height: 4rem;

						img {
							width: 100%;
							display: block;
						}
					}

					.txt {
						font-size: 2.25rem;
						font-family: PingFang SC;
						// margin-left: 0.625rem;
						font-weight: 600;
						color: white;
						line-height: 5rem;
						letter-spacing: 2px;
					}
				}

				.company {
					font-size: 2.25rem;
					font-family: ZhenyanGB;
					font-weight: 600;
					color: rgba(255, 255, 255, 1);
					margin: 1rem 0 0 1rem;
					letter-spacing: 4px;
				}
			}
		}

		.main-container {
			width: 80%;
			height: 100%;
			background-color: $mainBgc;
			padding: 0 1.25rem;
			// box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
			.header,
			.footer {
				height: 10.19%;
				width: 100%;
			}

			.content {
				width: 100%;
				height: 79.62%;
				background: white;
				border-radius: 0.625rem;
			}
		}

		.el-card {
			overflow: hidden !important;
			overflow-y: scroll !important;

			.title {
				font-size: 1.25rem !important;
				font-family: PingFang SC;
				font-weight: normal;
				color: #606266;
				letter-spacing: 2px;
				margin: 1.25rem 0;
			}
		}
		.fullScreen {
			width: 100%;
		}
	}
</style>
