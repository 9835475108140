import instance from './request'

// 获取菜单列表
export function getHomeMenuList(dataObj) {
  return instance({
    url: 'menu/getMenuList',
    method: 'POST',
    params: dataObj
  })
}

//获取动画视频资源
export function getSopFile(dataObj) {
  return instance({
    url: 'esop/getSlideFile',
    method: 'POST',
    params: dataObj
  })
}

//获取后台推送的消息
export function getMessage(dataObj) {
  return instance({
    url: 'menu/getMessage',
    method: 'POST',
    params: dataObj
  })
}

//获取工位列表
export function getDeptList(dataObj) {
  return instance({
    url: 'message/getDeptList',
    method: 'POST',
    params: dataObj
  })
}

// 发送消息
export function sendMsg(dataObj) {
  return instance({
    url: 'message/sendMsg',
    method: 'POST',
    params: dataObj
  })
}

//确认收到消息
export function readMessage(dataObj) {
  return instance({
    url: 'menu/readMessage',
    method: 'POST',
    params: dataObj
  })
}


// 数据采集
export function dataCollection(dataObj) {
  return instance({
    url: 'Data/collection',
    method: 'POST',
    params: dataObj
  })
}

// 获取灯光颜色
export function getLightColor(dataObj) {
  return instance({
    url: 'exception/getLight',
    method: 'POST',
    params: dataObj
  })
}