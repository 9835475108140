<template>
	<div class="img-container" v-if="isImgContainerShow">
		<el-button class="close" type="danger" size="medium" icon="el-icon-close" circle @click="closeImgContainer">
		</el-button>
		<el-button class="update" type="success" size="medium" icon="el-icon-refresh" circle @click="update">
		</el-button>
		<div class="carousel-container" v-if="pics && pics.length == 1">
			<div class="img-container" v-for="(item,index) in pics" :key="index" style="width: 100%; height: 100%;">
				<img v-lazy="item.fileUrl" width="100%" height="100%" preview="index" />
			</div>
		</div>
		<div class="carousel-container" v-else-if="pics && pics.length > 1">
			<!-- <swiper :options="swiperOption" ref="mySwiper" style="width: 100%; height: 100%;">
				<swiper-slide class="swiper-slide" v-for="(item,index) in pics" :key="item.fileId"
					style="width: 100%; height: 100%;">
					<img :src="item.fileUrl" v-if=" index == 0 || index == (pics.length - 1)" preview="0" width="100%"
						height="100%" />
					<img v-lazy="item.fileUrl" v-else preview="0" width="100%" height="100%" />
				</swiper-slide>
			</swiper> -->
			<div style="width: 100%; height: 100%;">
				<img v-lazy="pics[currentIndex].fileUrl" preview="0" width="100%" height="100%" />
			</div>
		</div>
		<div v-else class="remain-txt">
			暂 无 数 据
		</div>
		<div class="msg-container">
			公告消息：{{footerContent}}
		</div>
	</div>
</template>
<script>
	import {
		getSopFile,
	} from 'network/home'
	export default {
		name: "home",
		data() {
			return {
				// 全屏显示动画视频
				isImgContainerShow: true,
				currentIndex: 0,
				timer: null,
				swiperOption: {
					initialSlide: 0, //设定初始化时slide的索引
					width: window.innerWidth,
					height: window.innerHeight,
					direction: 'horizontal', //Slides的滑动方向，可设置水平(horizontal)或垂直(vertical)。
					loop: true, //无限滚动
					speed: 300, //滑动速度
					//自动轮播
					//autoplay: {
						//delay: 5000,
						//disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
						//stopOnLastSlide: false, // 在最后一页停止
					//},
					//开启鼠标滚轮控制Swiper切换
					mousewheel: true,
					zoom: false,
				},
			}
		},
		props: {
			footerContent: {
				type: String,
				default: "欢迎光临"
			},
			pics: {
				type: Array,
				default: []
			},
			isContentShow: {
				type: Number,
				default: 0
			}
		},
		created() {
			// 监听轮播组件传来的全屏点击事件
			this.$bus.$on("fullScreenChange", () => {
				this.isImgContainerShow = true;
			});
			// if(this.pics && this.pics.length > 1) {
				
			// }
			this.play();
		},
		methods: {
			closeImgContainer() {
				this.isImgContainerShow = false;
			},
			update() {
				// this.isImgContainerShow = true;
				this.$bus.$emit("updateSlide");
			},
			play() {
				this.timer = setInterval(() => {
					setTimeout(() => {
						this.currentIndex += 1;
						if(this.currentIndex > this.pics.length - 1) {
							this.currentIndex = 0;
							this.$bus.$emit("playVideo");
						}
					}, 0)
				}, 10000)
			}
		}
	}
</script>

<style lang="scss" scoped>
	/*横屏*/
	@media all and (orientation : landscape) {
		.swiper-slide img {
			height: 100%;
		}
	}
	/*竖屏*/
	@media all and (orientation : portrait) {
		.swiper-slide img {
			width: 100%;
			height: 100%;
		}
	}
	$mainBgc: #F4F5FA;
	$txtC: #3A84AB;

	.img-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: white;
		z-index: 999;
		overflow: hidden;
		// font-size: 26px;
		.close,
		.update {
			position: absolute;
			top: 1.25rem;
			right: 1.25rem;
			z-index: 1000;
			font-size: 2.5rem;
		}

		.update {
			position: absolute;
			top: 1.25rem;
			right: 4.375rem;
			z-index: 1000;
		}

		.carousel-container {
			width: 100%;
			height: 100%;
			text-align: center;
		}

		.remain-txt {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.57rem;
			color: #ggg;
			letter-spacing: 4px;
		}

		.msg-container {
			width: 100%;
			height: 5%;
			z-index: 9999;
			position: absolute;
			bottom: 1.25rem;
			font-size: 1.375rem;
			font-weight: 600;
			color: $txtC;
			letter-spacing: 2px;
			animation: donghua 15s linear infinite;
			-ms-animation: donghua 15s linear infinite;
			-moz-animation: donghua 15s linear infinite;
			-webkit-animation: donghua 15s linear infinite;
			-o-animation: donghua 15s linear infinite;
		}
	}


	@keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);

		}

		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);

		}
	}
	
	/* 兼容火狐 */
	@-moz-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);

		}

		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);

		}
	}
	
	/* Safari 和 Chrome */
	@-webkit-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);

		}

		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);

		}
	}
	
	@-o-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
	
	@-ms-keyframes donghua {
		0% {
			transform: translate(0%);
			-ms-transform: translate(0%);
			-moz-transform: translate(0%);
			-webkit-transform: translate(0%);
			-o-transform: translate(0%);
	
		}
	
		100% {
			transform: translate(100%);
			-ms-transform: translate(100%);
			-moz-transform: translate(100%);
			-webkit-transform: translate(100%);
			-o-transform: translate(100%);
	
		}
	}
</style>
