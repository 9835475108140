import instance from './request'

//获取异常列表
export function getExceptionList(dataObj) {
  return instance({
    url: 'exception/getExceptionList',
    method: 'POST',
    params: dataObj
  })
}

//呼叫异常
export function callException(dataObj) {
  return instance({
    url: 'exception/callException',
    method: 'POST',
    params: dataObj
  })
}

// 查看历史解决记录
export function getHistoryList(dataObj) {
  return instance({
    url: 'exception/getHistoryList',
    method: 'POST',
    params: dataObj
  })
}
// 取消呼叫
export function cancelCallException(dataObj) {
  return instance({
    url: 'exception/cancelCall',
    method: 'POST',
    params: dataObj
  })
}
//刷卡获取异常列表
export function getExceptionListByCardNo(dataObj) {
  return instance({
    url: 'exception/card',
    method: 'POST',
    params: dataObj
  })
}

//响应解除异常
export function handleException(dataObj) {
  return instance({
    url: 'exception/operationException',
    method: 'POST',
    params: dataObj
  })
}


